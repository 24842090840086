<template>
  <div style="min-height: 700px">
    <a-row type="flex" class="max-width" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <!-- <a-breadcrumb-item>产品列表</a-breadcrumb-item> -->
        <!-- 二级菜单 -->
        <a-breadcrumb-item>
          <a @click="to_c1" class="router">{{this.name_1}}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a v-if="this.name_2" @click="to_c2" class="router">{{this.name_2}}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a v-if="this.name_3" class="router">{{this.name_3}}</a>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>

    <!-- 静态展示 -->

    <!-- <div class="max-width" style="margin: 20px auto; max-width: 1200px;">
      <a-checkable-tag v-for="item in brand" :key="item.id" v-model="checked1">{{item.name}}</a-checkable-tag>
    </div>-->

    <!-- -- -->

    <CustomForm
      method="getAllProductForFranchisee"
      ref="CustomForm"
      isProducts
      noPagination
      v-if="!pageLoad"
      :defaultPageSize="24"
      :status=1
      :pageSizeOptions="['12', '24', '36', '48']"
      :params="params"
      :formItems="[
        { type: 'input', label: '模糊查询', key: 'name', note: '查询含关键字的产品' },
        { type: 'remote', label: '精确查询', key: 'id_product_info', note: '按名称精确匹配', method: 'remoteProduct' },
        { type: 'select', label: '排序', key: 'sort', default: 0, option: [
          { value: 0, label: '最新上架' },
          { value: 1, label: '最早上架' },
          { value: 2, label: '价格升序' },
          { value: 3, label: '价格降序' },
          { value: 4, label: '销量升序' },
          { value: 5, label: '销量降序' },
        ] },
        { type: 'show', label: '产品品牌',key: 'brand_product'},
      ]"
    >
      <!-- <a-form class="ant-advanced-search-form" :form="form" @submit="onSearch">
        <a-form-item label="产品品牌" class="brand">
          <a-checkable-tag v-for="item in brand" :key="item.id" v-model="checked1">{{item.name}}</a-checkable-tag>
        </a-form-item>
      </a-form>-->
      <a-spin :spinning="spinning">
        <!-- 产品列表 -->
        <div v-if="data.length != 0" style="text-align: center">
          <a-row class="main">
            <a-col class="card-col" v-for="(product, index) in data" :key="index">
              <a-card
                :body-style="{ padding: '0px' }"
                class="card"
                @click.native="toProduct(product)"
              >
                <div class="product_img">
                  <img v-lazy="product.url ? product.url : 'index/noimg.png'" />
                </div>
                <div style="padding: 14px;">
                  <div class="title">{{ product.name}}</div>
                  <h3 class="price" v-if="isShowPrice">￥{{ product.price_settle }}  / {{ product.product_unit }}</h3>
                  <h3
                    style="color:red;display:flex;justify-content:center"
                    v-if="!isShowPrice"
                  >登录后查看价格</h3>
                </div>
                <div justify="center" align="middle" class="button">
                  <a-button
                    type="primary"
                    :loading="isButtonLoading"
                    @click.stop="buy(product.id_product_info)"
                  >加入购物车</a-button>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
        <!-- 空状态提示 -->
        <div v-else style="text-align: center">
          <a-empty
            style="margin: 60px auto"
            :imageStyle="{ height: '200px' }"
            description="该分类下暂无产品"
          />
        </div>
      </a-spin>
    </CustomForm>
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      isShowPrice: true,
      isButtonLoading: false, // 是否显示按钮加载
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      params: {},
      pageLoad: true,
      spinning: true,
      data: [], // 产品列
      list: [], // 各级列
      id_product_category: null, // id
      name_2: null,
      name_3: null,
      name_1: null
    }
  },

  mounted() {
    if (this.getUser() == null) {
      this.isShowPrice = false
    }
    if (this.$route.params.name) {
      this.params.name = this.$route.params.name
    }
    if (this.$route.query.category) {
      this.params.id_product_category = this.$route.query.category
      this.id_product_category = this.$route.query.category
      this.get('getParentCategory', {
        id_product_category: this.id_product_category
      })
        .then((res) => {
          this.list = res
          this.name_1 = res[0].name
          this.name_2 = res[1].name
          this.name_3 = res[2].name
        })
        .catch(() => {})
    }
    this.pageLoad = false
  },

  methods: {
    toProduct(product) {
      const routeUrl = this.$router.resolve({
        path: 'product',
        query: { sn: product.sn_product_info }
      })
      window.open(routeUrl.href, '_blank')

      // this.$router.push({
      //   name: 'product',
      //   query: { id: product.id_product_info }
      // })
    },
    buy(id) {
      // console.log(id)
      this.isButtonLoading = true
      // 得到货品名称和最低购买量
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id_product_info === id) {
          var amount = this.data[i].num_minimum
          var name = this.data[i].name
        }
      }
      this.post('addCartForFranchisee', {
        id_product_info: id,
        amount: amount
      })
        .then(() => {
          this.isButtonLoading = false
          this.$message.success(`${name}*${amount} 已加入购物车`)
        })
        .catch((err) => {
          this.isButtonLoading = false
          this.$message.error(err.msg)
        })
    },
    // 回二级路由
    to_c1() {
      this.$router.replace({ name: 'home', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: this.list[0].id_product_category
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    },
    // 回二级路由
    to_c2() {
      this.$router.replace({ name: 'home', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: this.list[1].id_product_category
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    }
  },

  components: {
    CustomForm
  }
}
</script>

<style scoped>
.max-width {
  margin: 20px auto;
  padding: 0 50px;
  max-width: 1600px;
  min-width: 900px;
}
/* 卡片样式 */
.main {
  margin: 0;
  padding: 20px 0;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1600px;
}
/* 卡片 */
.card-col {
  width: 250px;
  margin-right: 50px;
  margin-bottom: 30px;
  text-align: left;
}
/* .card-col:nth-child(5n) {
  margin-right: 0px;
} */
.card {
  padding-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.card .button {
  opacity: 0;
}
.card:hover .button {
  opacity: 1;
}
.card:hover {
  /* background-color:rgba(52,127,155,0.5); */
  color: #347f9b;
  font-weight: 600;
  box-shadow: -15px 5px 20px -20px rgba(0, 0, 0, 0.64);
  transform: scale(1.01);
}
.card:hover .price {
  color: #347f9b;
}
.price {
  line-height: 6px;
  font-size: 13px;
  color: #f00;
}
.title {
  padding-bottom: 10px;
  height: 40px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product_img {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_img img {
  max-width: 100%;
  max-height: 280px;
  display: block;
}
/* 文字显示样式 */
.router:hover {
  color: #347f9b;
  text-decoration: underline;
}
/* 产品品牌 */
.brand {
  text-align: left;
}
</style>

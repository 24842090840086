var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"700px"}},[_c('a-row',{staticClass:"max-width",staticStyle:{"margin":"20px auto","max-width":"1600px"},attrs:{"type":"flex"}},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/'}},[_c('a-icon',{attrs:{"type":"home"}}),_vm._v("首页 ")],1)],1),_c('a-breadcrumb-item',[_c('a',{staticClass:"router",on:{"click":_vm.to_c1}},[_vm._v(_vm._s(this.name_1))])]),_c('a-breadcrumb-item',[(this.name_2)?_c('a',{staticClass:"router",on:{"click":_vm.to_c2}},[_vm._v(_vm._s(this.name_2))]):_vm._e()]),_c('a-breadcrumb-item',[(this.name_3)?_c('a',{staticClass:"router"},[_vm._v(_vm._s(this.name_3))]):_vm._e()])],1)],1),(!_vm.pageLoad)?_c('CustomForm',{ref:"CustomForm",attrs:{"method":"getAllProductForFranchisee","isProducts":"","noPagination":"","defaultPageSize":24,"status":1,"pageSizeOptions":['12', '24', '36', '48'],"params":_vm.params,"formItems":[
      { type: 'input', label: '模糊查询', key: 'name', note: '查询含关键字的产品' },
      { type: 'remote', label: '精确查询', key: 'id_product_info', note: '按名称精确匹配', method: 'remoteProduct' },
      { type: 'select', label: '排序', key: 'sort', default: 0, option: [
        { value: 0, label: '最新上架' },
        { value: 1, label: '最早上架' },
        { value: 2, label: '价格升序' },
        { value: 3, label: '价格降序' },
        { value: 4, label: '销量升序' },
        { value: 5, label: '销量降序' } ] },
      { type: 'show', label: '产品品牌',key: 'brand_product'} ]}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[(_vm.data.length != 0)?_c('div',{staticStyle:{"text-align":"center"}},[_c('a-row',{staticClass:"main"},_vm._l((_vm.data),function(product,index){return _c('a-col',{key:index,staticClass:"card-col"},[_c('a-card',{staticClass:"card",attrs:{"body-style":{ padding: '0px' }},nativeOn:{"click":function($event){return _vm.toProduct(product)}}},[_c('div',{staticClass:"product_img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(product.url ? product.url : 'index/noimg.png'),expression:"product.url ? product.url : 'index/noimg.png'"}]})]),_c('div',{staticStyle:{"padding":"14px"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(product.name))]),(_vm.isShowPrice)?_c('h3',{staticClass:"price"},[_vm._v("￥"+_vm._s(product.price_settle)+" / "+_vm._s(product.product_unit))]):_vm._e(),(!_vm.isShowPrice)?_c('h3',{staticStyle:{"color":"red","display":"flex","justify-content":"center"}},[_vm._v("登录后查看价格")]):_vm._e()]),_c('div',{staticClass:"button",attrs:{"justify":"center","align":"middle"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":function($event){$event.stopPropagation();return _vm.buy(product.id_product_info)}}},[_vm._v("加入购物车")])],1)])],1)}),1)],1):_c('div',{staticStyle:{"text-align":"center"}},[_c('a-empty',{staticStyle:{"margin":"60px auto"},attrs:{"imageStyle":{ height: '200px' },"description":"该分类下暂无产品"}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }